import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button } from '../../components/base';
import ContentTableStaff from '../../components/ContentTable/ContentTableStaffSK.component';
import { STAFF_TABLE_FIELDS } from '../../constants/personnel.constant';

export default class StaffList extends PureComponent {
  constructor(props) {
    super(props);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onClickView = this._onClickView.bind(this);
    
    this._onClickEdit = this._onClickEdit.bind(this);
    this._onClickDelete = this._onClickDelete.bind(this);
    this._onClickUpload = this._onClickUpload.bind(this);

    this._onClickSk = this._onClickSk.bind(this);
    this.customAction = [{
      Component: Button,
      props: {
        title: 'SK',
        className: 'staff-list__sk-button',
        onClick: this._onClickSk,
      },
    }];
  }


  _onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/personalia/kelola-staff', { data: '', isEdit: false });
  }
  _onClickView(val) {
    // console.log(this.props)
    const { history } = this.props;
    // console.log(val)
    history.push('/dashboard/personalia/view-staff', { data: val, isEdit: true });
  }

  _onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/personalia/kelola-staff', { data: val, isEdit: true });
  }

  _onClickDelete(id, data) {
    const { handleDeleteStaff } = this.props;
    return handleDeleteStaff({ id, nip: data.nip });
  }

  _onClickSk(val) {
    const { history } = this.props;
    history.push(`/dashboard/personalia/surat-keputusan/${val.nip}`,{ data: val });
  }

  _onClickUpload() {
    const { history } = this.props;
    history.push('/dashboard/personalia/upload-staff');
  }

  render() {
    const { handleGetDataPegawai,handleGetStaff, user } = this.props;
    let unit_kerja = 0;
    if(user.workingUnit){
        if(user.workingUnit.id != 0){
          unit_kerja = user.workingUnit.attributes.unit_code
        }
    }

    return (
      <div className="staff-list">
        <div className="staff-list__header">
          <div className="staff-list__button">
            {/* <Button
              title="Tambah Data Pegawai"
              onClick={this._onClickAdd}
            /> */}
          </div>
        </div>
        <div className="result__content">
          <ContentTableStaff
            unit_kerja = {unit_kerja}
            onSearchContent={handleGetStaff}
            tableFields={STAFF_TABLE_FIELDS}
            handleGetDataPegawai = {handleGetDataPegawai}
            onClickEdit={this._onClickEdit}
            onClickView={this._onClickView}
            onClickDelete={this._onClickDelete}
            onClickSK = {this._onClickSk}
          />
        </div>
      </div>
    );
  }
}
StaffList.propTypes = {
  handleGetStaff: PropTypes.func,
  handleDeleteStaff: PropTypes.func,
  handleGetDataPegawai : PropTypes.func,
  history: PropTypes.object.isRequired,
};
StaffList.defaultProps = {
  handleGetStaff: noop,
  handleDeleteStaff: noop,
  handleGetDataPegawai : noop,
};
