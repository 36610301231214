import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button } from '../../../components/base';
import ContentTable from '../../../components/ContentTable/ContentTableSK.component';
import { PERSONAL_INFORMATION_LETTER_TABLE_FIELDS } from '../../../constants/personnel.constant';

export default class InformationLetter extends PureComponent {
  constructor(props) {
    super(props);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onClickEdit = this._onClickEdit.bind(this);
    this._onClickDelete = this._onClickDelete.bind(this);
    this._onClickView = this._onClickView.bind(this);

    const { location } = props;
    const { nip } = location.state.data;
    this.initialParam = { nip };
    this.state ={
        datapegawai : '',
        kota : '',
        unit_kerja : '',
    }
  }

  componentDidMount(){
    this.getData();
  } 

  async getData(){
    const {handleGetDataPegawai, history, location} =this.props;
    const { data } = location.state;
    const datapegawai = await handleGetDataPegawai({nip:data.nip});
    this.setState({datapegawai: datapegawai.result, kota : datapegawai.result.unit_kerja.kota, 
        unit_kerja : datapegawai.result.unit_kerja.id_unit_kerja})
  }

  _onClickAdd() {
    const { history, location } = this.props;
    const { data } = location.state;
    history.push(`/dashboard/personalia/tambah-surat-keputusan/${data.nip}`, 
    { data: { nip: data.nip, unit_kerja : this.state.unit_kerja }, isEdit: false });
  }

  _onClickEdit(id) {
    const { history, location } = this.props;
    const data = location.state.data;
    console.log(this.state.unit_kerja)
    history.push(`/dashboard/personalia/tambah-surat-keputusan/${data.nip}`,
    { data: { id:id,nip: data.nip, unit_kerja : this.state.unit_kerja }, isEdit: true });
  }

  _onClickDelete(id) {
    const { handleDeleteInformationLetter, location } = this.props;
    const { data } = location.state;
    return handleDeleteInformationLetter({ nip: data.nip, id });
  }

  _onClickView(id){
    const { history, location } = this.props;
    const data = location.state.data;
    history.push(`/dashboard/personalia/preview-surat-keputusan`, 
      { id:id, data:this.state.datapegawai, kota :this.state.kota });
  }

  _onClickBack = () =>{
    const { history,user } = this.props;
    history.push('/dashboard/personalia/data-sk');
  };

  render() {
    const { handleGetInformationLetter } = this.props;
    return (
      <div >
        <Button
          title="Tambah Surat Keputusan"
          onClick={this._onClickAdd}
        />
        <br/>
        <Button 
          title="Kembali"
          onClick = {this._onClickBack}
        />
        <div className="information-letter__content">
          <ContentTable
            initialParams={this.initialParam}
            onSearchContent={handleGetInformationLetter}
            tableFields = {PERSONAL_INFORMATION_LETTER_TABLE_FIELDS}
            onClickEdit={this._onClickEdit}
            onClickView={this._onClickView}
            onClickDelete={this._onClickDelete}
          />
        </div>
      </div>
    );
  }
}
InformationLetter.propTypes = {
  handleGetInformationLetter: PropTypes.func,
  handleDeleteInformationLetter: PropTypes.func,
  handleGetDataPegawai : PropTypes.func,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
InformationLetter.defaultProps = {
  handleGetInformationLetter: noop,
  handleDeleteInformationLetter: noop,
  handleGetDataPegawai: noop,
};
